export const leaderboardData = [
  {
    id: 1,
    name: 'Test Man',
    avatar: '/img/avatar/avatar1.png',
    friends: [2, 3, 4, 6, 8, 9, 15, 17, 18, 19],
    scores: {
      all: 58000,
      day: 9300,
      week: 11200,
      month: 93500,
      year: 115000,
    },
  },
  {
    id: 2,
    name: 'Jane Smith',
    avatar: './img/avatar/avatar2.png',
    friends: [1, 3],
    scores: {
      all: 13000,
      day: 250,
      week: 1100,
      month: 3000,
      year: 13000,
    },
  },
  {
    id: 3,
    name: 'Bob Johnson',
    avatar: '/img/avatar/avatar3.png',
    friends: [1, 2, 4],
    scores: {
      all: 14000,
      day: 280,
      week: 1150,
      month: 3200,
      year: 14000,
    },
  },
  {
    id: 4,
    name: 'Alice Williams',
    avatar: '/img/avatar/avatar4.png',
    friends: [1, 3],
    scores: {
      all: 12000,
      day: 200,
      week: 1000,
      month: 2800,
      year: 12000,
    },
  },
  {
    id: 5,
    name: 'Charlie Brown',
    avatar: '../img/avatar/avatar5.png',
    friends: [6, 7, 8],
    scores: {
      all: 18000,
      day: 320,
      week: 1400,
      month: 4000,
      year: 18000,
    },
  },
  {
    id: 6,
    name: 'Diana Miller',
    avatar: '/img/avatar/avatar6.png',
    friends: [5, 7],
    scores: {
      all: 11000,
      day: 180,
      week: 900,
      month: 2500,
      year: 11000,
    },
  },
  {
    id: 7,
    name: 'Ethan Davis',
    avatar: '/img/avatar/avatar7.png',
    friends: [5, 6, 8],
    scores: {
      all: 16000,
      day: 300,
      week: 1300,
      month: 3600,
      year: 16000,
    },
  },
  {
    id: 8,
    name: 'Fiona Moore',
    avatar: '/img/avatar/avatar8.png',
    friends: [5, 7],
    scores: {
      all: 10000,
      day: 150,
      week: 800,
      month: 2200,
      year: 10000,
    },
  },
  {
    id: 9,
    name: 'George Wilson',
    avatar: '/img/avatar/avatar9.png',
    friends: [10, 11, 12],
    scores: {
      all: 20000,
      day: 340,
      week: 1500,
      month: 4200,
      year: 20000,
    },
  },
  {
    id: 10,
    name: 'Hannah Lee',
    avatar: '/img/avatar/avatar10.png',
    friends: [9, 11],
    scores: {
      all: 12000,
      day: 200,
      week: 1000,
      month: 2800,
      year: 12000,
    },
  },
  {
    id: 11,
    name: 'Ian Taylor',
    avatar: '/img/avatar/avatar11.png',
    friends: [9, 10, 12],
    scores: {
      all: 14000,
      day: 240,
      week: 1100,
      month: 3200,
      year: 14000,
    },
  },
  {
    id: 12,
    name: 'Jessica Hall',
    avatar: '/img/avatar/avatar12.png',
    friends: [9, 11],
    scores: {
      all: 11000,
      day: 180,
      week: 900,
      month: 2500,
      year: 11000,
    },
  },
  {
    id: 13,
    name: 'Kevin White',
    avatar: '/img/avatar/avatar13.png',
    friends: [14, 15, 16],
    scores: {
      all: 15000,
      day: 300,
      week: 1200,
      month: 3500,
      year: 15000,
    },
  },
  {
    id: 14,
    name: 'Linda Clark',
    avatar: '/img/avatar/avatar14.png',
    friends: [13, 15],
    scores: {
      all: 13000,
      day: 220,
      week: 1050,
      month: 3000,
      year: 13000,
    },
  },
  {
    id: 15,
    name: 'Morgan Turner',
    avatar: '/img/avatar/avatar15.png',
    friends: [13, 14, 16],
    scores: {
      all: 14000,
      day: 250,
      week: 1100,
      month: 3200,
      year: 14000,
    },
  },
  {
    id: 16,
    name: 'Nathan Adams',
    avatar: '/img/avatar/avatar16.png',
    friends: [13, 15],
    scores: {
      all: 11000,
      day: 180,
      week: 900,
      month: 2500,
      year: 11000,
    },
  },
  {
    id: 17,
    name: 'Olivia Hill',
    avatar: '/img/avatar/avatar17.png',
    friends: [18, 19, 20],
    scores: {
      all: 20000,
      day: 340,
      week: 1500,
      month: 4200,
      year: 20000,
    },
  },
  {
    id: 18,
    name: 'Paul King',
    avatar: '/img/avatar/avatar18.png',
    friends: [17, 19],
    scores: {
      all: 12000,
      day: 200,
      week: 1000,
      month: 2800,
      year: 12000,
    },
  },
  {
    id: 19,
    name: 'Quincy Baker',
    avatar: '/img/avatar/avatar19.png',
    friends: [17, 18, 20],
    scores: {
      all: 14000,
      day: 280,
      week: 1150,
      month: 3200,
      year: 14000,
    },
  },
  {
    id: 20,
    name: 'Rachel Scott',
    avatar: '/img/avatar/avatar20.png',
    friends: [17, 19],
    scores: {
      all: 10000,
      day: 150,
      week: 800,
      month: 2200,
      year: 10000,
    },
  },
];
