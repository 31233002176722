import React, { useState, useRef, useCallback } from 'react';
import {
  Box,
  Button,
  VStack,
  Circle,
  Image,
  IconButton,
} from '@chakra-ui/react';
import { FaCamera } from 'react-icons/fa';
import Webcam from 'react-webcam';
import { useAuth } from '../context/AuthContext.jsx';
import { setAvatar } from './cookiesService';
import { BG_COLOR } from '../App.jsx';
import logoText from '../img/main/LogoText.png';

export default function Camera({ isCameraOn, setIsCameraOn }) {
  const [imgSrc, setImgSrc] = useState(null);
  const [camPermission, setCamPermission] = useState(false);
  const { refreshUser } = useAuth();
  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef]);

  const saveAvatar = () => {
    setAvatar(imgSrc);
    refreshUser();
    setImgSrc(null);
    setIsCameraOn(false);
  };

  const activatCam = () => {
    setCamPermission(true);
  };

  return (
    <Box
      display={isCameraOn ? 'flex' : 'none'} // Hide the component when not in use
      justifyContent="center"
      alignItems="center"
      position="fixed"
      top="0"
      left="0"
      w="100vw"
      h="100vh"
      bg={BG_COLOR}
      zIndex="3"
    >
      <Image
        src={logoText}
        alt="logoText"
        position="fixed"
        mt={`-450px`}
        w={`100%`}
        maxW={`500px`}
        pl={30}
        pr={30}
      />
      {imgSrc ? (
        <VStack spacing={4}>
          <Circle size={[`250px`, `300px`]} overflow="hidden" boxShadow="lg">
            <Image src={imgSrc} alt="Snapshot" boxSize="100%" />
          </Circle>
          <Button colorScheme="green" onClick={saveAvatar}>
            Save
          </Button>
          <Button colorScheme="red" onClick={() => setImgSrc(null)}>
            Retake
          </Button>
        </VStack>
      ) : (
        <VStack spacing={4}>
          <Circle size={[`250px`, `300px`]} overflow="hidden" boxShadow="lg">
            {camPermission ? ( // Display the camera
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                  width: 300,
                  height: 300,
                  facingMode: 'user',
                }}
                mirrored={true}
              />
            ) : (
              <IconButton // Activate the camera
                icon={<FaCamera />}
                size="lg"
                colorScheme="blue"
                onClick={activatCam}
              />
            )}
          </Circle>
          <Button colorScheme="green" onClick={capture}>
            Capture photo
          </Button>
          <Button colorScheme="orange" onClick={() => setIsCameraOn(false)}>
            Cancel
          </Button>
        </VStack>
      )}
    </Box>
  );
}
