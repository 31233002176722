export const userData = [
  {
    id: 1,
    email: 'test@example.com',
    password: '123456$',
    idToken: '1234567890',
    user: 'Test Man',
    avatar: '../img/avatar/avatar1.png',
    friends: [2, 3, 4, 6, 8, 9, 15, 17, 18, 19],
    scores: {
      all: 58000,
      day: 9300,
      week: 11200,
      month: 93500,
      year: 115000,
    },
  },
];
