import React, { useState, useEffect } from 'react';
import VideoComponent from '../components/video.jsx';
import Questions from '../components/questions.jsx';
import { videoDataBase } from '../localServer/videoDataBase.jsx';
import Countdown from '../components/countDown.jsx';
import { Box } from '@chakra-ui/react';
import EndGame from '../components/endGame.jsx';

//Initialize the gameData object with the question, answer, correct, and timeAnswered properties.
function initializeGameData(video) {
  const videoData = video;
  const initialGameData = {};

  Object.keys(videoData.question).forEach(key => {
    initialGameData[key] = {
      question: videoData.question[key],
      answer: videoData.answer[key],
      correct: false,
      complete: false,
      userAnswer: '',
    };
  });
  return initialGameData;
}
let gameData = {};

export default function Game() {
  const [video, setVideo] = useState();
  const [mainTime, setMainTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [endGame, setEndGame] = useState(false);
  const [maxPauses] = useState(0); //set max pauses to 0 for now upadate later
  const [numQuestions, setNumQuestions] = useState(0);
  const [activeQuestion, setActiveQuestion] = useState('1');
  const [restart, setRestart] = useState(0); //for css code on countDown.jsx

  useEffect(() => {
    // Ensure video data is available
    if (videoDataBase[0]) {
      // Set the video state to the first video in the database
      setVideo(videoDataBase[0]);
      // Set the main timer to the duration of the video
      setMainTime(videoDataBase[0].duration);
      // Set gameData to initial values based on the video data
      gameData = initializeGameData(videoDataBase[0]);
      // Set the number of questions to the number of questions in the video
      setNumQuestions(Object.keys(videoDataBase[0].question).length);
    }
  }, []);

  // Main timerTimer for the game
  useEffect(() => {
    let intervalId;
    if (isPlaying) {
      intervalId = setInterval(() => {
        setMainTime(oldValue => {
          if (oldValue <= 1) {
            clearInterval(intervalId);
            // gameData[activeQuestion].complete = true;
            setActiveQuestion(numQuestions); // Last question when time runs out
            gameData[numQuestions].complete = true;
            gameData[numQuestions].correct = false;
            setEndGame(true);
            return 0;
          } else {
            const questionTime = video.duration / numQuestions;
            const timeIntoVideo = video.duration - oldValue + 1;
            let curQuest = Math.floor(timeIntoVideo / questionTime) + 1;
            if (curQuest > numQuestions) {
              curQuest = numQuestions;
            }
            if (activeQuestion < `${curQuest}`) {
              gameData[activeQuestion].complete = true;
              gameData[activeQuestion].correct = false;
              setActiveQuestion(`${curQuest}`);
            }
            return oldValue - 1;
          }
        });
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isPlaying, mainTime, video, numQuestions, activeQuestion]);

  useEffect(() => {
    if (endGame) {
      // If endGame is true, pause the game by setting isPlaying to false
      setIsPlaying(false);
    }
  }, [endGame]);

  // Restart the game by setting all the states to their initial values and clearing the gameData object
  const restartGames = () => {
    //get new video from database
    setVideo(videoDataBase[0]);
    setMainTime(videoDataBase[0].duration);
    setIsPlaying(false);
    gameData = initializeGameData(videoDataBase[0]);
    setNumQuestions(Object.keys(videoDataBase[0].question).length);
    setRestart(prev => prev + 1);
    setActiveQuestion('1');
    setEndGame(false);
  };

  return (
    <Box>
      {video ? (
        <>
          <Countdown
            mainTimer={true}
            mainTime={mainTime}
            time={video.duration}
            isPlaying={isPlaying}
            restart={restart}
            gameData={gameData}
            numQuestions={numQuestions}
          />
          <VideoComponent
            url={video.url}
            maxPauses={maxPauses}
            setIsPlaying={setIsPlaying}
            restart={restart}
            isPlaying={isPlaying}
          />
          <Questions
            type={video.type}
            isPlaying={isPlaying}
            gameData={gameData}
            activeQuestion={activeQuestion}
            setActiveQuestion={value => setActiveQuestion(value)}
            numQuestions={numQuestions}
            setEndGame={value => setEndGame(value)}
          />
          <EndGame
            isOpen={endGame}
            onClose={() => restartGames()}
            gameData={gameData}
            timeFinished={mainTime}
            stats={video.stats}
            scorePerAns={video.score}
            multiplier={video.multiplier}
            numQuestions={numQuestions}
          />
        </>
      ) : (
        <Box>Loading...</Box>
      )}
    </Box>
  );
}
