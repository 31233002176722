import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Box,
  Flex,
  HStack,
  Text,
  Spacer,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Image,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { BG_COLOR } from '../App';
import { useAuth } from './../context/AuthContext';
import logoText from '../img/main/LogoText2.png';

export default function Navbar() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isSmallerThan300px] = useMediaQuery('(max-width: 300px)');

  // Navigation items as an array to reuse them for both small and large screens
  const navItems = [
    { label: 'leaderboard', path: '/leader' },
    // { label: 'Play', path: '/main' },
    // {
    //   label: user.user === 'Guest' ? 'Login' : 'Logout',
    //   path: '/login',
    // },
  ];

  return (
    <Box>
      <Flex
        maxW="100vw"
        h={'60px'}
        align="center"
        justify="space-between"
        bg={BG_COLOR}
        color="white"
      >
        <Box w="100%">
          <HStack>
            {/* Logo */}
            <HStack maxW={`40%`}>
              <Link to="/">
                <Image src={logoText} alt="logo" width="100px" ml={2} />
              </Link>
              <Menu>
                {/* Display menu on small screens */}
                <MenuButton
                  display={['flex', 'flex', 'none']}
                  as={IconButton}
                  aria-label="Options"
                  icon={<HamburgerIcon />}
                  variant="outline"
                />
                <MenuList zIndex={100}>
                  {navItems.map(item => (
                    <MenuItem
                      key={item.label}
                      onClick={() => navigate(item.path)}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </HStack>
            {/* Display navigation items on large screens */}
            <HStack display={['none', 'none', 'flex']}>
              {navItems.map(item => (
                <Text
                  key={item.label}
                  cursor={'pointer'}
                  onClick={() => navigate(item.path)}
                  fontSize={['md', 'lg']}
                  fontWeight="semibold"
                  px={3}
                >
                  {item.label}
                </Text>
              ))}
            </HStack>
            <Spacer />
            {/* Display scores in the centre */}
            <VStack
              position={`absolute`} // This is to allow the scores to be positioned in the centre
              left={`50%`}
              transform={`translateX(-50%)`}
              align="center"
            >
              <Text fontSize={[`md`, `lg`]} mb={-4}>
                score
              </Text>
              <Text fontSize={['md', 'lg']}>
                {user.scores?.all ? user.scores.all : 0}
              </Text>
            </VStack>
            <Spacer />
            {/* Display guest and login but once loged in only display avatar */}
            {user.idToken === null ? (
              <HStack maxW={`40%`}>
                <Text fontSize={['md', 'lg']} isTruncated>
                  {user.user}
                </Text>
                <Link to="/login">
                  <Text
                    fontSize={['md', 'lg']}
                    fontWeight="bold"
                    pr={2}
                    cursor={'pointer'}
                  >
                    login
                  </Text>
                </Link>
              </HStack>
            ) : (
              <Link to="/login">
                <Avatar
                  key={user.avatar}
                  size={'md'}
                  mr={2}
                  src={user.avatar}
                  bg="teal.500"
                  name={user.name}
                />
              </Link>
            )}
          </HStack>
        </Box>
      </Flex>
    </Box>
  );
}
