import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, IconButton } from '@chakra-ui/react';
import ReactPlayer from 'react-player';

export default function VideoComponent({
  url,
  maxPauses,
  isPlaying,
  setIsPlaying,
  restart,
}) {
  const [pauseCount, setPauseCount] = useState(0);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const playerRef = useRef(null);
  const handlePlay = () => {
    setIsPlaying(true);
    setIsPaused(false);
    // setPauseCount(2);
  };

  useEffect(() => {
    //St the video to the beggining when the game restarts
    setIsPaused(true);
    setPauseCount(0);
    if (playerRef.current && playerRef.current.seekTo) {
      playerRef.current.seekTo(0);
    }
  }, [restart]);

  //if game is finished, pause the video
  useEffect(() => {
    if (isPlaying === false) {
      setIsPaused(true);
    }
  }, [isPlaying]);

  // Toggle mute/unmute
  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const handlePause = () => {
    // If the pause count is less than the max pauses allowed, increment the pause count
    // also set the playing status to false
    if (pauseCount !== maxPauses) {
      setPauseCount(prevPauseCount => {
        if (prevPauseCount < maxPauses) {
          setIsPaused(true);
          setIsPlaying(false);
          return prevPauseCount + 1;
        } else {
          setIsPaused(false);
          setIsPlaying(true);
          return prevPauseCount;
        }
      });
    }
  };

  return (
    <Box style={{ position: 'relative' }}>
      <ReactPlayer
        ref={playerRef}
        //Set max width of the player to 400px and centre the video
        style={{ margin: '0 auto' }}
        url={url}
        playsinline={true}
        height={'100%'}
        width={'100%'}
        muted={isMuted}
        controls={false}
        playing={!isPaused}
        onClick={handlePause}
        onReady={() => setBtnDisabled(false)}
        onBuffer={() => setIsPlaying(false)}
        onBufferEnd={() => setIsPlaying(true)}
      />
      {isPaused && (
        <Box
          style={{
            // background to cover the whole video
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            // center the content
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // darken the background
            background: 'rgba(0, 0, 0, 0.8)',
          }}
        >
          <Button
            bg={'green'}
            m={5}
            onClick={handlePlay}
            isDisabled={btnDisabled}
          >
            {pauseCount === 0 ? 'Start' : 'Resume'}
          </Button>
          <p>
            {maxPauses > 0
              ? `You can pause the video ${maxPauses - pauseCount} more ${
                  maxPauses - pauseCount === 1 ? 'time' : 'times'
                }.`
              : `You can't pause the video once started.`}
          </p>
        </Box>
      )}
      <Box
        // aria-label={isMuted ? 'Unmute' : 'Mute'}
        onClick={toggleMute}
        pos={'absolute'}
        bottom={'10px'}
        right={'10px'}
        zIndex={10}
        bg="blackAlpha.200"
        borderRadius={'full'}
        p={1}
      >
        {!isPlaying ? `` : isMuted ? '🔇' : '🔊'}{' '}
        {/* Place emojis as children */}
      </Box>
    </Box>
  );
}
