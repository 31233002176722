// NewsTicker.jsx
import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const NewsTicker = () => {
  const scrollStyle = {
    display: 'inline-block', // Use inline-block for individual items
    animation: 'scroll 20s linear infinite',
    whiteSpace: 'nowrap',
  };

  const newsItemStyle = {
    display: 'inline', // inline for a continuous line of text
  };

  const separatorStyle = {
    display: 'inline', // inline to continue the text line
    margin: '2em', // Space around the separator
  };

  const newsItems = [
    'Breaking News: New Features Released!',
    'Scheduled Maintenance on Sunday',
    'New Game Mode coming soon!',
    'Watch out for new game features!',
    // ... other news items
  ];

  const renderNewsItems = () => {
    const renderedItems = [];
    newsItems.forEach((item, index) => {
      // Add news item
      renderedItems.push(
        <Text key={`item-${index}`} style={newsItemStyle}>
          {item}
        </Text>
      );
      // Add separator if not the last item
      if (index < newsItems.length - 1) {
        renderedItems.push(
          <span key={`sep-${index}`} style={separatorStyle}>
            *
          </span>
        );
      }
    });
    return renderedItems;
  };

  return (
    <Box
      position="fixed"
      bottom="0"
      width="full"
      maxW={'1000px'}
      borderTopLeftRadius={10}
      borderTopRightRadius={10}
      bg="gray.700"
      color="white"
      p={4}
      style={{ overflow: 'hidden' }}
    >
      <div style={scrollStyle}>{renderNewsItems()}</div>
    </Box>
  );
};

// Inline keyframes and animation
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(
  `
  @keyframes scroll {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
  }
`,
  styleSheet.cssRules.length
);

export default NewsTicker;
