import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useMediaQuery,
} from '@chakra-ui/react';

const EndGame = ({
  isOpen,
  onClose,
  gameData,
  timeFinished,
  stats,
  scorePerAns,
  multiplier,
  numQuestions,
}) => {
  const navigate = useNavigate();
  const [isSmallerThan300px] = useMediaQuery('(max-width: 300px)');

  const fontSize = isSmallerThan300px ? 'xs' : 'md';

  const score = () => {
    let score = 0;
    let isbonus = 0;
    Object.keys(gameData).forEach(key => {
      if (gameData[key].correct) {
        score += scorePerAns;
        isbonus += 1;
      }
    });
    // let bonus = multiplier * timeFinished;
    // if (isbonus === numQuestions) {
    //   return score + bonus;
    // } else {
    return score;
    // }
  };

  //   const totalScore = gameData.reduce((acc, item) => acc + item.score, 0);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent m={10} p={2} maxW="1000px" bg="gray.700">
        <ModalHeader>Game Summary</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Table variant="striped" colorScheme="" size={['sm', `xl`]}>
            <Thead>
              <Tr>
                <Th>Stat</Th>
                <Th>Value</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Score</Td>
                <Td>{`${score()} out of ${scorePerAns * numQuestions}`}</Td>
              </Tr>

              {Object.entries(stats).map(([key, value]) => (
                <Tr key={key}>
                  {key === 'funFact' ? (
                    <Td colSpan="2" pt={5} pb={5}>
                      {value}
                    </Td>
                  ) : (
                    <>
                      <Td>{value[0]}</Td>
                      <Td>{value[1]}</Td>
                    </>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="green"
            mr={3}
            fontSize={fontSize}
            onClick={() => onClose()}
          >
            Play Again
          </Button>

          <Button
            colorScheme="purple"
            fontSize={fontSize}
            onClick={() => navigate('/leader')}
          >
            Leaderboard
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EndGame;
