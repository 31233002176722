import React, { useCallback, useState } from 'react';
import {
  LoginSocialFacebook,
  LoginSocialGoogle,
  //   LoginSocialMicrosoft,
  //   LoginSocialApple,
  //   IResolveParams,
} from 'reactjs-social-login';

import {
  FacebookLoginButton,
  GoogleLoginButton,
  //   MicrosoftLoginButton,
  //   AppleLoginButton,
} from 'react-social-login-buttons';
import { Button } from '@chakra-ui/react';

// const REDIRECT_URI =
//   'https://plenty-planets-beam-42-118-51-2.loca.lt/account/login';
// const REDIRECT_URI = 'http://localhost:3000/#/login';

const SocialLoginComponent = () => {
  const [provider, setProvider] = useState('');
  const [profile, setProfile] = useState();

  const responseMessage = (provider, data) => {
    console.log(data);
    alert(
      `Logged in with ${provider} successfully. \n\n Access token to be sent to server \n\n ${
        provider === `facebook` ? data.accessToken : data.access_token
      }`
    );
  };

  const onLoginStart = useCallback(() => {
    alert('login start');
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider('');
    alert('logout success');
  }, []);

  return (
    <>
      {provider && profile && <Button onClick={onLogoutSuccess} />}
      <LoginSocialFacebook
        isOnlyGetToken
        appId={'1101908961223175'}
        onLoginStart={onLoginStart}
        // redirect_uri={REDIRECT_URI}
        version="v18.0"
        onResolve={({ provider, data }) => {
          responseMessage(provider, data);
          setProvider(provider);
          setProfile(data);
        }}
        onReject={err => {
          console.log(err);
        }}
      >
        <FacebookLoginButton />
      </LoginSocialFacebook>

      <LoginSocialGoogle
        isOnlyGetToken
        client_id={`1072891631951-llfbovgk07p81adnaap6i9mmrl3nrjp6.apps.googleusercontent.com`}
        onLoginStart={onLoginStart}
        // redirect_uri={REDIRECT_URI}
        onResolve={({ provider, data }) => {
          responseMessage(provider, data);
          setProvider(provider);
          setProfile(data);
        }}
        onReject={err => {
          console.log(err);
        }}
      >
        <GoogleLoginButton />
      </LoginSocialGoogle>

      {/* <LoginSocialApple
          client_id={process.env.REACT_APP_APPLE_ID || ''}
          scope={'name email'}
          redirect_uri={REDIRECT_URI}
          onLoginStart={onLoginStart}
          onResolve={({ provider, data }) => {
            setProvider(provider);
            setProfile(data);
          }}
          onReject={err => {
            console.log(err);
          }}
        >
          <AppleLoginButton />
        </LoginSocialApple>

        <LoginSocialMicrosoft
          client_id={process.env.REACT_APP_MICROSOFT_APP_ID || ''}
          redirect_uri={REDIRECT_URI}
          onLoginStart={onLoginStart}
          onResolve={({ provider, data }) => {
            setProvider(provider);
            setProfile(data);
          }}
          onReject={(err: any) => {
            console.log(err);
          }}
        >
          <MicrosoftLoginButton />
        </LoginSocialMicrosoft> */}
    </>
  );
};

export default SocialLoginComponent;
