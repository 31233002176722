//creat video database with video url and video id 3 questions and answers. THere will be bank of answers that all the videos will pull from. some answere will be correct and some will be wrong.
// its based on animal videos and the questions will be about the animals.
//questions during the video clip, related to Phylum, Class & Order, with “smart” dropdown
//this is just to test our app
//want to import video from img/video folder

import lionVideo from '../img/videos/lion.mp4';

export const videoDataBase = [
  {
    id: 1,
    //get video from img/video folder
    url: lionVideo,
    type: 'animals',
    duration: 60,
    multiplier: 1,
    score: 15,
    stats: {
      weight: ['Weight', '190 kg'],
      speed: ['Top Speed', '80 km/h'],
      length: ['Length', '2.5 m'],
      country: ['Continent', 'Africa'],
      funFact: 'Lions are the only cats that live in groups called prides.',
    },
    question: {
      1: 'What is the Phylum of this animal?',
      2: 'What is the Class of this animal?',
      3: 'What is the Order of this animal?',
    },
    answer: {
      1: 'Chordata',
      2: 'Mammalia',
      3: 'Carnivora',
    },
  },
  {
    id: 2,
    url: lionVideo,
    type: 'animals',
    duration: 45,
    question: {
      1: 'What is the Phylum of this animal?',
      2: 'What is the Class of this animal?',
      3: 'What is the Order of this animal?',
    },
    answer: {
      1: 'Chordata',
      2: 'Mammalia',
      3: 'Carnivora',
    },
  },
];
