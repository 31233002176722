import { userData } from '../localServer/userDataBase';

export default async function EmailLogin(email, password) {
  // const type = 'login'
  try {
    // local server to test
    // const response = await fetch('http://localhost:3001/login', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({ email, password , type}),
    // });
    // // Check if the response is not OK (status 2xx)
    // if (!response.ok) {
    //   throw new Error(`HTTP error! Status: ${response.status}`);
    // }
    // const data = await response.json();

    //#########################################################

    const user = userData.find(user => user.email === email);

    if (!user || user.password !== password) {
      return { res: false, message: 'Invalid email or password' };
    }

    return {
      res: true,
      user: user.user,
      idToken: user.idToken,
      avatar: user.avatar,
      scores: user.scores,
      message: 'Login successful',
    };

    // return { res: true, data };
  } catch (error) {
    console.error('Error during login:', error);
    return { res: false, data: error.message || 'Unknown error' };
  }
}
