import React, { createContext, useState, useContext } from 'react';
import { mainUser } from '../components/cookiesService.jsx';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(mainUser());

  const refreshUser = () => {
    setUser(mainUser());
  };

  return (
    <AuthContext.Provider value={{ user, refreshUser }}>
      {children}
    </AuthContext.Provider>
  );
};
