// theme.js

import { extendTheme } from '@chakra-ui/react';
//import the css file from the fonts folder
import './theme.css';
// import { theme } from '@chakra-ui/react';

const theme = extendTheme({
  config: {
    initialColorMode: 'dark', // Set to 'light' or 'dark' as needed
  },
  fonts: {
    heading: 'Comfortaa, sans-serif',
    body: 'Comfortaa, sans-serif',
  },
});

export default theme;
