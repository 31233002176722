import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import {
  ChakraProvider,
  ColorModeScript,
  Box,
  useMediaQuery,
} from '@chakra-ui/react';
import Navbar from './routes/Navbar';
import Login from './routes/Login';
import Leader from './routes/Leader';
import Game from './routes/Game.jsx';
import Home from './routes/Home';
import theme from './theme.js';
import NewsSticker from './routes/NewsSticker.jsx';

export const BG_COLOR =
  'linear-gradient(0deg, rgba(2,1,2,1) 0%, rgba(22,14,7,1) 53%, rgba(2,1,2,1) 100%)';

const App = () => {
  const [isSmallerThan300px] = useMediaQuery('(max-width: 300px)');
  // Apply background color to the body
  document.body.style.background = BG_COLOR;

  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Box
        display="flex"
        justifyContent="center"
        w="100%"
        overflowX={'hidden'}
        maxW={'100%'}
        minH="100vh"
        pb="60px"
        fontSize={[`${isSmallerThan300px ? `10px` : `14px`}`, `16px`]}
      >
        <Box color="white" maxW="1000px" w="100%">
          <Router>
            <Navbar />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/leader" element={<Leader />} />
              <Route path="/game" element={<Game />} />
              <Route path="/" element={<Home />} />
            </Routes>
            <NewsSticker />
          </Router>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default App;
