import Cookies from 'universal-cookie';
import AnswerBank from '../localServer/answerBankDB';

// Generate a random guest ID choosing one of the answers from the answer bank type animals
const randomGuestID = `guest_${
  AnswerBank('animals')[
    Math.floor(Math.random() * AnswerBank('animals').length)
  ].substring(0, 7) // Limit the selected word to 7 characters
}`.substring(0, 11); // Ensure the whole ID is no more than 14 characters

const defaultUser = {
  user: randomGuestID,
  idToken: null,
  avatar: null,
  scores: {
    all: 0,
    day: 0,
    week: 0,
    month: 0,
    year: 0,
  },
};

const cookies = new Cookies();
let userLoggedIn = !!cookies.get('AnimalSpotr')
  ? cookies.get('AnimalSpotr')
  : defaultUser;

export const isLoggedIn = () => {
  return !!cookies.get('AnimalSpotr'); // Adjust this based on your cookie structure
};

export const login = data => {
  cookies.set(
    'AnimalSpotr',
    {
      user: data.user,
      idToken: data.idToken,
      avatar: data.avatar,
      scores: data.scores,
    },
    { path: '/' }
  );
  userLoggedIn = data;
};

export const logout = () => {
  cookies.remove('AnimalSpotr', { path: '/' });
  userLoggedIn = defaultUser;
};

export const mainUser = () => {
  return userLoggedIn;
};

export const setAvatar = avatar => {
  userLoggedIn = { ...userLoggedIn };
  userLoggedIn.avatar = avatar;
  cookies.set('AnimalSpotr', userLoggedIn, { path: '/' });
};
