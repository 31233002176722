import React, { useEffect, useState } from 'react';
import AnswerBank from '../localServer/answerBankDB.jsx';
import {
  Input,
  Box,
  UnorderedList,
  ListItem,
  Button,
  HStack,
  VStack,
  Grid,
} from '@chakra-ui/react';

export default function Questions({
  type,
  isPlaying,
  gameData,
  activeQuestion,
  setActiveQuestion,
  numQuestions,
  setEndGame,
}) {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const answers = AnswerBank(type);

  useEffect(() => {
    // Clear suggestions when new question is active
    setInputValue('');
    setSuggestions([]);
  }, [activeQuestion]);

  const handleInputChange = event => {
    const value = event.target.value;
    setInputValue(value);

    if (value.length > 0) {
      // Filter the answers for suggestions that start with the input value
      const filteredSuggestions = answers.filter(answer =>
        answer.toLowerCase().startsWith(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = suggestion => {
    setInputValue(suggestion);
    setSuggestions([]);
  };

  const handleAnswerSubmit = () => {
    gameData[activeQuestion].complete = true;
    gameData[activeQuestion].correct =
      inputValue === gameData[activeQuestion].answer;
    if (numQuestions > parseInt(activeQuestion)) {
      setActiveQuestion((parseInt(activeQuestion) + 1).toString());
    } else {
      setSuggestions([]);
      setEndGame(true);
      setInputValue('');
    }
    setInputValue('');
    setSuggestions([]);
  };

  return (
    <Box>
      <VStack>
        {/*############## Question box ########### */}
        <Box>
          {!isPlaying || gameData[activeQuestion].complete
            ? `Round ${activeQuestion}:`
            : `Round ${activeQuestion}: ${gameData[activeQuestion].question}`}
        </Box>
        {/*############## Answer box ########### */}
        <HStack spacing={1} pl={'4%'}>
          <Input
            isDisabled={!isPlaying || gameData[activeQuestion].complete}
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Type your answer..."
          />
          {/*############## Submit Button ########### */}
          <Button
            isDisabled={!isPlaying || gameData[activeQuestion].complete}
            onClick={() => {
              handleAnswerSubmit();
            }}
            w={['80px', '150px']}
            borderLeftRadius={20}
            bg={'green.500'}
            fontSize={['xs', 'md']}
          >
            Submit
          </Button>
          {/*############## Clear Button ########### */}
          <Button
            onClick={() => setInputValue('')}
            w={['80px', '150px']}
            bg={'red.500'}
            isDisabled={!isPlaying || gameData[activeQuestion].complete}
            fontSize={['xs', 'md']}
          >
            Clear
          </Button>
        </HStack>
      </VStack>
      {/*############## Suggestions Answers ########### */}
      {suggestions.length > 0 && (
        <UnorderedList align={`center`} m={0} pt={5} listStylePos={`inside`}>
          <Grid
            templateColumns={{
              base: 'repeat(1, 1fr)', // 1 column for small screens
              sm: 'repeat(2, 1fr)', // 2 columns for medium screens
              md: 'repeat(3, 1fr)', // 3 columns for large screens
            }}
            gap={4} // Adjust the gap size as needed
          >
            {suggestions.map((suggestion, index) => (
              <ListItem
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                // Apply any additional styling you need for ListItem here
              >
                {` ${suggestion}`}
              </ListItem>
            ))}
          </Grid>
        </UnorderedList>
      )}
    </Box>
  );
}
