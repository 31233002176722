import React from 'react';
import { Box } from '@chakra-ui/react';
import './countDown.css';

const Countdown = ({
  mainTime,
  time,
  isPlaying,
  gameData,
  numQuestions,
  restart,
}) => {
  //################ Calculate the bar and background colors ################

  const calculateColors = () => {
    const proportion = mainTime / time; // 0 when time is up, 1 when full

    const green = Math.floor(170 * proportion); // Decreases as time decreases
    const red = 255 - Math.floor(140 * proportion); // Increases as time decreases

    const bgColor = `gray.700`;
    const barColor = `rgba(${red}, ${green}, 0, 1)`;
    return { bgColor, barColor };
  };

  const { barColor, bgColor } = calculateColors();

  //#########################  End  #########################

  const animationStyle = {
    animationDuration: `${time}s`,
    animationPlayState: isPlaying ? 'running' : 'paused',
  };

  return (
    <Box
      h={50}
      w="100%"
      display={'flex'}
      pos={'relative'}
      border={'1px solid black'}
      bg={bgColor}
    >
      {/*Time left in seconds*/}
      <Box
        h="100%"
        w="100%"
        alignItems={'flex-start'}
        justifyContent={'center'}
        display={'flex'}
        pos={'absolute'}
        zIndex={3}
      >
        <Box
          bg="rgba(0, 0, 0, 0.3)"
          borderRadius="10px"
          pb={'2px'}
          pl="10px"
          pr="10px"
        >
          {`${mainTime}s`}
        </Box>
      </Box>
      {/*Bar animation box*/}
      <Box
        key={restart}
        h="100%"
        bgColor={barColor}
        className="countDown"
        pos={'absolute'}
        zIndex={2}
        style={animationStyle}
      />
      {gameData &&
        Object.keys(gameData)
          .reverse()
          .map(key => (
            <Box
              key={key}
              display={'flex'}
              justifyContent="center"
              alignItems={'flex-end'}
              zIndex={5}
              h="100%"
              w="100%"
              maxW={`${100 / numQuestions}%`}
              borderLeft={'2px solid black'}
              pos={'flex'}
              left={`${key * (100 / numQuestions)}%`}
              color={
                gameData[key].complete
                  ? gameData[key].correct
                    ? 'green'
                    : 'red'
                  : 'none'
              }
            >
              {!gameData[key].complete
                ? `Round: ${key}`
                : `${gameData[key].answer}${
                    gameData[key].correct ? '✅' : '❌'
                  }`}
            </Box>
          ))}
    </Box>
  );
};

export default Countdown;
