import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  FormErrorMessage,
  HStack,
  VStack,
  Heading,
  Avatar,
  Box,
  Image,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import EmailLogin from '../components/emailLogin';
import {
  isLoggedIn,
  login,
  logout,
  mainUser,
} from '../components/cookiesService';
import EmailResiater from '../components/emailRegister';
import Camera from '../components/camera';
import logoText from '../img/main/LogoText.png';
import SocialLoginComponent from '../components/socialLogin';

export default function Login() {
  const { refreshUser } = useAuth();
  const [email, setEmail] = useState('test@example.com');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('123456$');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [repeatError, setRepeatError] = useState('');
  const [blurEmail, setBlurEmail] = useState(false);
  const [blurPassword, setBlurPassword] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [curUser, setCurUser] = useState('');
  const [nameError, setNameError] = useState('');
  const [blurName, setBlurName] = useState('');
  const [loading, setLoading] = useState(true); // Added loading state
  const [curLoggedOn, setCurLoggedOn] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const specialCharsRegex = /[!"£$%^&*@?]/;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setCurLoggedOn(isLoggedIn());
        setCurUser(mainUser().user);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleLogin = async () => {
    const loginRes = await EmailLogin(email, password);
    if (loginRes.res) {
      login(loginRes);
      refreshUser();
      navigate('/');
    } else {
      setPasswordError(loginRes.message);
    }
  };

  const handleRegister = async () => {
    const registerRes = await EmailResiater(email, password, curUser);
    if (registerRes.res) {
      login(registerRes);
      refreshUser();
      navigate('/');
    } else {
      setEmailError(registerRes.message);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (isLogin) {
      handleLogin();
    } else {
      handleRegister();
    }
  };

  // Handle email change
  const handleChangeEmail = e => {
    const newChar = e;
    setEmail(newChar);
    if (newChar === '') setEmailError('The Email address field is required');
    else if (!newChar.includes('@'))
      setEmailError('Please enter a valid email address');
    else if (!newChar.includes('@'))
      setEmailError('Please enter a valid email address');
    else setEmailError(false);
  };
  // Handle user name
  const handleCurUser = e => {
    const newChar = e;
    setCurUser(newChar);
    if (newChar === '') setNameError('The User Name field is required');
    else if (newChar.length < 4)
      setNameError('The User Name must be 5 or more characters');
    else if (newChar.length > 12) setNameError('The User Name is too long');
    else setNameError(false);
  };

  // Handle password change
  const handleChangePassword = e => {
    const newChar = e;
    setPassword(newChar);
    if (repeatPassword === '') setRepeatError(false);
    if (newChar === '') setPasswordError('The Password field is required');
    else if (newChar.length < 6)
      setPasswordError('The Password must be 6 or more characters');
    else if (!specialCharsRegex.test(newChar))
      setPasswordError(`The Password must include ${specialCharsRegex}`);
    else if (newChar.length > 20) setPasswordError('The Password is too long');
    else setPasswordError(false);
  };

  // Handle repeat password change
  const handleRepeatPassword = e => {
    const newChar = e;
    setRepeatPassword(newChar);
    if (newChar !== password) setRepeatError('The Passwords do not match');
    else setRepeatError(false);
  };

  // Handle Login/Register change
  const handleClick = e => {
    setIsLogin(e);
    setCurUser('');
    setEmailError(``);
    setPassword('');
    setRepeatPassword('');
    setPasswordError('');
  };

  const logoutClick = () => {
    logout();
    refreshUser();
    setCurLoggedOn(false);
  };

  return (
    <Box align={`center`}>
      <Image
        src={logoText}
        alt="logo"
        pt={`40px`}
        pl={10}
        pr={10}
        w={`100%`}
        maxW={`500px`}
      />
      <Box
        w={['85%', '400px']}
        borderWidth="2px"
        borderRadius="lg"
        bg={'blackAlpha.700'}
        mt={`20px`}
      >
        {loading ? (
          <Button
            align="centre"
            isLoading
            loadingText="Loading"
            colorScheme="gray"
            variant="outline"
            spinnerPlacement="start"
            w="95%"
          ></Button>
        ) : (
          <>
            {curLoggedOn ? (
              <VStack spacing={4} align="center" p={10}>
                <Avatar
                  size={'2xl'}
                  src={mainUser().avatar}
                  // onClick={() => setIsCameraOn(true)}
                >
                  <Button
                    size="sm"
                    position="absolute"
                    bottom="0"
                    right="0"
                    transform="translate(50%, 50%)"
                    onClick={() => setIsCameraOn(true)}
                  >
                    <EditIcon />
                  </Button>
                </Avatar>

                <Heading as="" size="xl" maxW="110%" noOfLines={1}>
                  {curUser}
                </Heading>
                <Heading as="" size="l" noOfLines={2}>
                  Do you want to log out:
                </Heading>
                <Button bg={'orange'} onClick={() => logoutClick()}>
                  Logout
                </Button>
              </VStack>
            ) : (
              <form>
                <HStack w="100%" pb="20px">
                  <Button
                    w="50%"
                    h="50px"
                    mr={-1}
                    display={'flex'}
                    alignItems={'center'}
                    textAlign={'center'}
                    justifyContent="center"
                    variant={isLogin ? 'solid' : 'ghost'}
                    color={isLogin ? 'white' : 'gray'}
                    fontSize={isLogin ? '2rem' : '1.8rem'}
                    borderTopLeftRadius="lg"
                    onClick={() => handleClick(true)}
                  >
                    Login:
                  </Button>
                  <Button
                    w="50%"
                    h="50px"
                    ml={-1}
                    display={'flex'}
                    alignItems={'center'}
                    textAlign={'center'}
                    justifyContent="center"
                    variant={!isLogin ? 'solid' : 'ghost'}
                    color={!isLogin ? 'white' : 'gray'}
                    fontSize={!isLogin ? '2rem' : '1.8rem'}
                    borderTopRightRadius="lg"
                    onClick={() => handleClick(false)}
                  >
                    Register
                  </Button>
                </HStack>
                <Stack spacing={4} p={5}>
                  <SocialLoginComponent />
                  <FormControl
                    isRequired
                    isInvalid={emailError}
                    onBlur={() => {
                      if (email !== '') setBlurEmail(true);
                    }}
                  >
                    <FormLabel>Email address</FormLabel>
                    <Input
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={e => handleChangeEmail(e.target.value)}
                    />
                    {blurEmail && emailError && (
                      <FormErrorMessage>{emailError}</FormErrorMessage>
                    )}
                  </FormControl>

                  {!isLogin && (
                    <FormControl
                      isRequired
                      isInvalid={nameError}
                      onBlur={() => {
                        if (curUser !== '') setBlurName(true);
                      }}
                    >
                      <FormLabel>User Name</FormLabel>
                      <Input
                        type="text"
                        placeholder="Enter your user name"
                        value={curUser}
                        onChange={e => handleCurUser(e.target.value)}
                      />
                      {blurName && nameError && (
                        <FormErrorMessage>{nameError}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                  <FormControl
                    isRequired
                    isInvalid={passwordError}
                    onBlur={() => {
                      if (password !== '') setBlurPassword(true);
                    }}
                  >
                    <FormLabel>Password</FormLabel>
                    <Input
                      type="password"
                      placeholder="Enter your password"
                      value={password}
                      onChange={e => handleChangePassword(e.target.value)}
                    />
                    {blurPassword && passwordError && (
                      <FormErrorMessage>{passwordError}</FormErrorMessage>
                    )}
                  </FormControl>
                  {!isLogin && (
                    <FormControl isRequired isInvalid={repeatError}>
                      <FormLabel>Re-enter Password</FormLabel>
                      <Input
                        type="password"
                        placeholder="Enter your password"
                        value={repeatPassword}
                        onChange={e => handleRepeatPassword(e.target.value)}
                      />
                      {repeatError && (
                        <FormErrorMessage>{repeatError}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}

                  <Button
                    type="submit"
                    bg="gray.600"
                    size="lg"
                    fontSize="md"
                    onClick={handleSubmit}
                    isDisabled={
                      !isLogin
                        ? repeatPassword === ''
                        : false ||
                          password === '' ||
                          email === '' ||
                          emailError ||
                          passwordError ||
                          repeatError ||
                          nameError
                    }
                  >
                    {isLogin ? 'Login' : 'Register'}
                  </Button>
                </Stack>
              </form>
            )}
          </>
        )}
      </Box>
      <Camera
        isCameraOn={isCameraOn}
        setIsCameraOn={value => setIsCameraOn(value)}
      />
    </Box>
  );
}
