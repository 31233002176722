// answer bank for the quiz pass in props with type of answer back you want to use and it will return an array of answers
export default function AnswerBank(type) {
  //default is unable to load answers
  //if type is not specified it will return this
  switch (type) {
    case 'animals':
      return [
        //animals answers bank array of answers phylum, class, order no duplicates
        'Cnidaria',
        'Porifera',
        'Platyhelminthes',
        'Nematoda',
        'Annelida',
        'Mollusca',
        'Arthropoda',
        'Echinodermata',
        'Chordata',
        'Cubozoa',
        'Scyphozoa',
        'Hydrozoa',
        'Anthozoa',
        'Calcarea',
        'Demospongiae',
        'Hexactinellida',
        'Acoela',
        'Turbellaria',
        'Trematoda',
        'Cestoda',
        'Nematomorpha',
        'Oligochaeta',
        'Polychaeta',
        'Sipuncula',
        'Bivalvia',
        'Gastropoda',
        'Cephalopoda',
        'Arachnida',
        'Myriapoda',
        'Insecta',
        'Crustacea',
        'Echinoidea',
        'Holothuroidea',
        'Ophiuroidea',
        'Asteroidea',
        'Chondrichthyes',
        'Osteichthyes',
        'Amphibia',
        'Reptilia',
        'Aves',
        'Mammalia',
        'Carnivora',
        'Monotremata',
        'Marsupialia',
        'Insectivora',
        'Dermoptera',
        'Chiroptera',
        'Primates',
        'Rodentia',
        'Lagomorpha',
        'Cetacea',
        'Sirenia',
        'Proboscidea',
        'Hyracoidea',
        'Perissodactyla',
        'Artiodactyla',
        'Pholidota',
        'Cingulata',
        'Xenarthra',
        'Didelphimorphia',
        'Paucituberculata',
        'Dasyuromorphia',
        'Notoryctemorphia',
        'Peramelemorphia',
        'Diprotodontia',
        'Scandentia',
        'Tetrapoda',
        'Eulipotyphla',
        'Euarchontoglires',
        'Glires',
        'Placentalia',
        'Marsupiala',
        'Reptiliomorpha',
        'Testudines',
        'Squamata',
        'Crocodilia',
        'Neognathae',
        'Palaeognathae',
        'Prototheria',
        'Theria',
        'Eutheria',
        'Metatheria',
      ];
    case 'plants':
      return [
        'Bryophyta',
        'Marchantiophyta',
        'Anthocerotophyta',
        'Lycopodiophyta',
        'Equisetophyta',
        'Psilotophyta',
        'Pteridophyta',
        'Ginkgophyta',
        'Cycadophyta',
        'Gnetophyta',
        'Pinophyta',
        'Magnoliophyta',
        'Bryophyta',
        'Marchantiophyta',
        'Anthocerotophyta',
        'Lycopodiophyta',
        'Equisetophyta',
        'Psilotophyta',
        'Pteridophyta',
        'Ginkgophyta',
        'Cycadophyta',
        'Gnetophyta',
        'Pinophyta',
        'Magnoliophyta',
        'Bryophyta',
        'Marchantiophyta',
        'Anthocerotophyta',
        'Lycopodiophyta',
        'Equisetophyta',
        'Psilotophyta',
        'Pteridophyta',
        'Ginkgophyta',
        'Cycadophyta',
        'Gnetophyta',
        'Pinophyta',
        'Magnoliophyta',
        'Bryophyta',
        'Marchantiophyta',
        'Anthocerotophyta',
        'Lycopodiophyta',
        'Equisetophyta',
        'Psilotophyta',
        'Pteridophyta',
        'Ginkgophyta',
        'Cycadophyta',
        'Gnetophyta',
        'Pinophyta',
        'Magnoliophyta',
        'Bryophyta',
        'Marchantiophyta',
        'Anthocerotophyta',
        'Lycopodiophyta',
        'Equisetophyta',
        'Psilotophyta',
        'Pteridophyta',
        'Ginkgophyta',
        'Cycadophyta',
        'Gnetophyta',
        'Pinophyta',
        'Magnoliophyta',
        'Bryophyta',
        'Marchantiophyta',
      ];
    default:
      return ['Unable to load answers'];
  }
}
